<template>
  <div class="elite-tabs-wrapper-content">
    <div class="resume-header">
      <div class="resume-header-image">
        <img
          v-if="reseller.logo"
          :src="`${reseller.logo}`"
          class="argon-image"
        />
        <img v-else src="/img/add-image.svg" class="default" />
      </div>
      <div class="resume-header-text">
        <h3>{{ reseller.name }}</h3>
        <ul>
          <li>{{ reseller.email }}</li>
        </ul>
      </div>
    </div>
    <div class="resume-list">
      <div class="resume-list-inner">
        <dl class="width_1-2">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            <div v-html="reseller.excerpt"></div>
          </dd>
        </dl>
        <dl class="width_1-2">
          <dt>
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd>
            {{
              reseller.phone_type
                ? $t(`COMMON.PHONE_TYPE_${reseller.phone_type}`)
                : null
            }}:
            {{ reseller.phone }}
            {{
              reseller.phone_extension
                ? ` (ext: ${reseller.phone_extension})`
                : null
            }}
          </dd>
        </dl>
        <dl class="width_1-2">
          <dt>
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd>
            <span v-for="(line, index) in reseller.other_phones" :key="index">
              {{ $t(`COMMON.PHONE_TYPE_${line.type}`) }}:
              {{ line.phoneNumber }}
              {{ line.extension ? ` (ext: ${line.extension})` : null }} <br />
            </span>
          </dd>
        </dl>
        <dl class="width_1-2">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ reseller.address }}
          </dd>
        </dl>
        <dl class="width_1-2">
          <dt>{{ $t("COMMON.OWNER") }}</dt>
          <dd>
            <template v-if="!!reseller.owner">
              <object-link
                v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
                :object="reseller.owner"
              />
              <span v-else>{{ $objectDenomination(reseller.owner) }}</span>
            </template>
          </dd>
        </dl>
        <dl class="width_1-2">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="reseller.created_at">
            {{ $timeZoneDateFormat(reseller.created_at) }}
          </dd>
        </dl>
        <dl class="width_1-2">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="reseller.updated_at">
            {{ $timeZoneDateFormat(reseller.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
    <div class="mb-4 mt-4">
      <h3>{{ $t("RESELLERS.CONFIG_MANAGER") }}</h3>
    </div>
    <div class="config-manager">
      <div class="config-manager-image">
        <img
          v-if="reseller.config_manager_app_logo"
          :src="`${reseller.config_manager_app_logo}`"
          class="argon-image"
        />
        <img v-else src="/img/add-image.svg" class="default-image" />
      </div>
      <div class="config-manager-text">
        <dl>
          <dt>
            {{ $t("RESELLERS.CONFIG_MANAGER_APP_NAME") }}
          </dt>
          <dd>
            {{ reseller.config_manager_app_name }}
          </dd>
        </dl>
        <dl>
          <dt>
            {{ $t("RESELLERS.CONFIG_MANAGER_URL_REGEX") }}
          </dt>
          <dd>
            {{ reseller.config_manager_url_regex }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reseller-view-global",

  components: {},

  props: ["reseller"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    reseller(reseller) {},
  },
};
</script>
